import React from 'react';
import styles from './Hero.module.scss';
import { ReactComponent as GoDown } from '../../assets/images/home/go_down.svg';

const HeroComponent = ({scrollDown, title, isScroll=false}) => {

  return (
    <section className={styles["hero"]}>
        <article className={styles["wrapper"]}>
            <h2 className={styles["title"]}>
                <span>{title.line1}</span>
                <span>{title.line2}</span>
                <span>{title.line3}</span>
            </h2>
            <figure onClick={() => scrollDown()} className={styles[`${isScroll ? 'go_down' : 'go_down-hide'}`]}>
                <GoDown />
                {/* <GoDownArrow /> */}
            </figure>
        </article>
    </section>
  )
}

export default HeroComponent