import React from "react";
import Testimonial from "../../components/testimonial/Testimonial";
import styles from "./Works.module.scss";
import HeroComponent from "../../components/hero/Hero";
import { Projects } from "../../components/projects/Projects";

const Works = () => {

  return (
    <main className={styles["works"]}>
      <HeroComponent title={{line1:'Our works,',line2:'them birth',line3:'growth'}} />
      <Projects list={{vertex:true,manor:true,finova:true, gr8:true}} />
      <Testimonial />

    </main>
  );
};

export default Works;
