import styles from "./ServiceCards.module.scss";
import React, { Fragment } from "react";
import { allServices } from "./data";
import Krent from "../../../components/krent/Krent";
import { ServiceCard } from "../../../components/cards/Cards";

export const FullCards = () => {
  const [web, design, brand] = allServices;
  return (
    <section className={styles["fullcards"]}>
      <article className={styles["brand"]} key={brand.title}>
        <figure className={styles["image"]}>
          <div className={styles["image__wrapper"]}>
            <img src={brand.icon} alt={brand.title} />
          </div>
        </figure>
        <div className={styles["text"]}>
          <h2>{web.title}</h2>
          <p>{web.text}</p>
          {/* <button className={styles["learn-more"]}>
            Learn more{" "}
            <span>
              <img src={arrowRight} alt="learn more" />
            </span>
          </button> */}
        </div>
      </article>

      <article className={styles["service-krent"]}>
        <Krent title={design.title} details={design.text} type="large"/>
      </article>

      <article className={styles["service"]} key={web.title}>
        <figure className={styles["image"]}>
          <img className={styles["img"]} src={web.icon} alt={web.title} />
        </figure>
        <div className={styles["text"]}>
          <h2>{brand.title}</h2>
          <p>{brand.text}</p>
          {/* <button className={styles["learn-more"]}>
            Learn more{" "}
            <span>
              <img src={arrowRight} alt="learn more" />
            </span>
          </button> */}
        </div>
      </article>

    </section>
  );
};

export const LargeCards = () => {
  return (
    <>
      {allServices.slice(3).map((service) => {
        return (
          <Fragment key={service.title}>
            <ServiceCard size="large" cardProps={service} />
          </Fragment>
        );
      })}
    </>
  );
};
