import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from './layout';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import Services from './pages/services/Services';
import Works from './pages/works/Works';



function App() {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' element={<Home />} />
          <Route path='home'  element={<Navigate to="/" />}/>
          <Route path='about-us' element={<About />} />
          <Route path='services' element={<Services />} />
          <Route path='works' element={<Works />} />
          <Route path='contact' element={<Contact />} />
          <Route path='*'  element={<Navigate to="/" />}/>
        </Route>
      </Routes>
    </>
  );
}

export default App;
