import React from "react";
import styles from "./Form.module.scss";
import axios from "axios";
import { useState } from "react";

const Form = () => {
  let [errMessage, setErrMessage] = useState("");
  let [isProcessing, setIsProcessing] = useState(false);
  let submitedFormDetails = {
    full_name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setErrMessage("");
    setIsProcessing(true);

    if (
      e.target[0].value.trim() &&
      e.target[1].value &&
      e.target[2].value &&
      e.target[3].value
    ) {
      submitedFormDetails = {
        ...submitedFormDetails,
        full_name: e.target[0].value,
        phone: e.target[1].value,
        email: e.target[2].value,
        subject: e.target[3].value,
        message: e.target[4].value,
      };

      axios
        .post("https://webxiel-api.com/contactapi/", submitedFormDetails, {
          headers: {
            API_SECRET:
              "webxiel_contact_secret-x3PYawK-K8ORKS-9HyfHILkPNhR.wxD4S",
            API_KEY: "webxiel_contact_key-o42VecfqSnPe1V1",
          },
        })
        .then((resp) => {
          if (resp.data?.status === true) {
            e.target[0].value = "";
            e.target[1].value = "";
            e.target[2].value = "";
            e.target[3].value = "";
            e.target[4].value = "";
            alert("Message sent successfully");
          } else {
            let msg = resp.data?.message
              ? resp.data.message
              : "An error occured, please try again or send an email";
            setErrMessage(msg);
          }
          setIsProcessing(false);
        })
        .catch((err) => {
          setErrMessage("An error occured, please try again or send an email");
          setIsProcessing(false);
        });
    } else {
      setErrMessage("Some fields are missing, kindly check and try again");
      setIsProcessing(false);
    }
  }
  return (
    <form className={styles["form"]} onSubmit={handleSubmit}>
      <div className={styles["form_container"]}>
        <div className={styles["form-control"]}>
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Enter your full name"
            required
          />
        </div>

        <div className={styles["form-control"]}>
          <label htmlFor="phone">Phone</label>
          <input
            type="tel"
            name="phone"
            id="phone"
            placeholder="Enter your phone number"
            required
          />
        </div>

        <div className={styles["form-control"]}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Type in your email address"
            required
          />
        </div>

        <div className={styles["form-control"]}>
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            name="subject"
            id="subject"
            placeholder="Please enter the subject"
            required
          />
        </div>
      </div>

      <div className={styles["form-control"]}>
        <label htmlFor="name">Message</label>
        <textarea
          name="message"
          id="message"
          placeholder="Please enter any additional message"
          required
        />
      </div>
      <p className={styles["err-message"]}>{errMessage}</p>
      <div className={styles["form-control"]}>
        {isProcessing ? (
          <button className={styles["btn__processing"]} disabled type="submit">
            Processing...
          </button>
        ) : (
          <button type="submit">Send message</button>
        )}
      </div>
    </form>
  );
};

export default Form;
