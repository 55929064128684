import React from 'react';
import styles from './Services.module.scss';
import Testimonial from '../../components/testimonial/Testimonial';
import { LargeCards } from './cards/ServiceCards';
import Results from './results/Results';
import Krent from '../../components/krent/Krent';
import HeroComponent from '../../components/hero/Hero';
import manor_md from '../../assets/images/manor_md.png';
import greenBug_md from '../../assets/images/greenBug_md.png';
import chevronRight2 from '../../assets/images/services/chevron-right2.svg';
import chevronRightSm from '../../assets/images/chevron-right-sm.svg';
import { Projects } from '../../components/projects/Projects';

const Services = () => {

  return (
    <main className={styles["services"]}>
      {/* <Hero /> */}
      <HeroComponent title={{line1:'We shape',line2:'products and',line3:'services.'}} />
      <section className={styles["container__cards"]}>
        <section className={styles["fullcards"]}>
          <article className={styles["service"]} >
            <figure className={styles["image"]}>
              <img src={manor_md} alt='the manor' />
              {/* <div className={styles["image__wrapper"]}>
              </div> */}
            </figure>
            <div className={styles["text"]}>
              <h2>Web/app development</h2>
              <p>Stop losing sales to your competitors! Stay one step ahead of your competitors with the professional website we would develop for your business.</p>
              <button className={styles["learn-more"]}>
                Learn more{" "}
                <span>
                  <picture>
                    <source media="(min-width: )" srcset={chevronRight2} />
                    <img src={chevronRightSm} alt="learn more" />
                  </picture>
                  {/* <img src={chevronRight2} alt="learn more" /> */}
                </span>
              </button>
            </div>
          </article>

          <article className={styles["service-krent"]}>
            <Krent 
              title={"Systematic design for products"} 
              details={"Are you thinking of building your online presence for your business? Its time to let us design a professional website for your Business."} 
              type="large"
            />
          </article>

          <article className={styles["service"]}>
            <figure className={styles["image"]}>
              <img className={styles["img"]} src={greenBug_md} alt="green Bud" />
            </figure>
            <div className={styles["text"]}>
              <h2>Timeless brand identities</h2>
              <p>A quality brand identity makes you stand out among competitors and attracts bigger clients for your business. Let's give your business the unique branding it needs to be exceptional.</p>
              <button className={styles["learn-more"]}>
                Learn more{" "}
                <span>
                <picture>
                    <source media="(min-width: )" srcset={chevronRight2} />
                    <img src={chevronRightSm} alt="learn more" />
                  </picture>
                </span>
              </button>
            </div>
          </article>

        </section>
        <div className={styles["largeCards__container"]}>
          <h2 className={styles["largeCards__services_title"]}>
            <span>More</span>
            <span>services</span>
          </h2>
          <section className={styles["largeCards"]}>
            <LargeCards />
          </section>
        </div>
      </section>
      <section className={styles['results']}>
        <Results />
      </section>
      <Projects list={{manor:true}} />
      <Testimonial />
    </main>
  )
}

export default Services