import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Topbar from '../components/top-bar'
import Footer from '../components/footer'
import ScrollToTop from '../components/scroll/ScrollToTop'


const Layout = () => {
  const [ close, setClose ] = useState(false);
  const [showNav, setShowNav] = useState(false);

  function handleCloseNav() {
    if(showNav === true){
      setClose(true);
      closeNav();
    } else{
      setClose(false);
    }
  }

  useEffect(() => {
    if(showNav === true){
      closeNav();
      const timer = setTimeout(()=> {
        setClose(false);
      }, 1000);
      return () => {
        clearTimeout(timer);
      }
    }

  }, [close])
  
  
  function closeNav() {
    if((close === true) && (showNav === true)){
        setShowNav(false);
    }
  }

  return (
    <div onClick={handleCloseNav}>
        <ScrollToTop />
        <Topbar showNav={showNav} closeNav={setShowNav}/>
        <Outlet />
        <Footer />
    </div>
  )
}

export default Layout