import React from "react";
import styles from "./Contact.module.scss";
import Form from "./form/Form";
import Testimonial from "../../components/testimonial/Testimonial";
import { ReactComponent as Calling } from "../../assets/images/Calling2.svg";

const Contact = () => {
  return (
    <main className={styles["contact-us"]}>
      <section className={styles["contact"]}>
        <header className={styles["header"]}>
          <h1 className={styles["header__title"]}>Get in touch</h1>
          <p className={styles["header__text"]}>
            Simply fill out this form or{" "}
            <a href="mailto:hello@webxiel.io">send us a mail</a>
          </p>
        </header>
        <div className={styles["form-wrapper"]}>
          <Form />
        </div>
        {/* <Office /> */}
      </section>
      <Testimonial />
      <section className={styles["offices"]}>
        <div className={styles["offices_container"]}>
          <h2 className={styles["offices_header"]}>We are here...</h2>
          <div className={styles["offices_wrapper"]}>
            <div className={styles["office"]}>
              <p className={styles["type"]}>Head office</p>
              <p className={styles["location"]}>
                Raviradantie 7, 50100 Mikkeli, Finland
              </p>
              <p className={styles["tel"]}>
                <span>
                  <Calling />
                </span>
                <a href="tel:+358417087043">+358417087043</a>
              </p>
            </div>
            <div className={styles["office"]}>
              <p className={styles["type"]}>Branch office</p>
              <p className={styles["location"]}>
                Central Business District, Phase 1, Abuja
              </p>
              <p className={styles["tel"]}>
                <span>
                  <Calling />
                </span>
                <a href="tel:+358417087043">+2347014346470</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
