import styles from './Krent.module.scss';
import { ReactComponent as ArrowRight } from '../../assets/images/arrrow-right.svg';
import chevronRight2 from '../../assets/images/arrrow-right.svg';
import chevronRightSm from '../../assets/images/chevron-right-sm.svg';

import krentBlue from '../../assets/images/krent-blue.svg';
import krentCards from '../../assets/images/krent-cards.svg';
import krentProfile from '../../assets/images/krent-profile.svg';
import krentAnimate from '../../assets/images/krent-cards-animate.svg';

import React from 'react'

const krentInfo = {
    title: "Krent",
    details: "Research, brand strategy, naming, logo design, visual language, tone of voice, brand assets, campaigns. Research, brand strategy, ."
  }

const Krent = ({title = krentInfo.title, details=krentInfo.details, type}) => {
  return (
    <div className={type === "large"? styles["krent-large"] : styles["krent"]}>
        <section className={styles["text"]}>
            <div className={styles["wrapper"]}>
                <h2 className={styles["title"]}>{title}</h2>
                <p className={styles["details"]}>{details}</p>
                <p className={styles["learn-more"]}>
                    <span>Learn more</span> 
                    <span className={styles["arrow-right"]}>
                        <picture>
                            <source media="(min-width: )" srcset={chevronRight2} />
                            <img src={chevronRightSm} alt="learn more" />
                        </picture>
                    </span>
                </p>
            </div>
        </section>
        <section className={styles["figures"]}>
            <figure className={styles["homescreen"]}>
                <img src={krentBlue} alt="homescreen" />
            </figure>
            <figure className={styles["profile"]}>
                <img src={krentProfile} alt="user-profile" loading='lazy'/>
            </figure>
            <figure className={styles["cards"]}>
                <img className={styles["cards__img"]} src={krentCards} alt="cards" />
                <div className={styles["cards__animate"]}>
                    <img src={krentAnimate} alt="cards design"/>
                </div>
            </figure>
        </section>
    </div>
  )
}

export default Krent