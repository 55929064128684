import React from 'react';
import styles from './Cards.module.scss';

// the size should be large or small
export const ServiceCard = ({size, cardProps}) => {
    const {title, text, icon, details} = cardProps;
  return (
    <>
        {
            size === "small" ? 
            (
                <div className={styles['card-small']}>
                    <h2 className={styles['title']}>{title}</h2>
                    <p className={styles['text']}>{text}</p>
                </div>

            ) :
            (
                <div className={styles['card-large']}>
                    <figure className={styles['icon']}>
                        <img src={icon} alt="icon"/>
                    </figure>
                    <div className={styles['description']}>
                        <h2 className={styles['title']}>{title}</h2>
                        <p className={styles['text']}>{text}</p>
                    </div>
                    {/* <p className={styles['learn-more']}>
                        <Link to={details}>
                            <span>Learn more </span> <span className={styles['arrow-right']}><ArrowRight /></span>
                        </Link>
                    </p> */}
                </div>
            )
        }
    </>
  )
}
