import { ReactComponent as Performance } from '../../../assets/images/services/icon-performance.svg';
import { ReactComponent as Delivery } from '../../../assets/images/services/icon-deliver.svg';
import { ReactComponent as Secured } from '../../../assets/images/services/icon-secured.svg';




export const resultFactors = [
    {
        icon: <Performance />,
        title: "Best performance",
        details: "We perform at a consistently higher level than others. Our performance is always at its peak in a bid to meet your business Goals."
      },
      {
        icon: <Delivery />,
        title: "Deliver on time",
        details: "We want to skyrocket your business just as soon as you want it. So we deliver on Time and meet Deadlines."
      },
      {
        icon: <Secured />,
        title: "Powerful & fully secured",
        details: "Running a business in a highly secured environment is priceless. No threat from internet fraudsters. We’re offering that to your business on a silver platter."
      }
]