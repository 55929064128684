import styles from './Projects.module.scss';
import manor_lg from '../../assets/images/manor-lg.png';
import manor_sm from '../../assets/images/manor_sm.png';
import finova_lg from '../../assets/images/finova-lg.png';
import finova_sm from '../../assets/images/finova_sm.png';
import green_lg from '../../assets/images/green_bug-lg.png';
import green_sm from '../../assets/images/green_bug2-sm.png';
import vertex_lg from '../../assets/images/vertex-lg.png';
import vertex_sm from '../../assets/images/vertex-sm.png';
import chevronRight from '../../assets/images/services/chevron-right2.svg';
import gr8main_lg from '../../assets/images/gr8-main-lg.png';
import gr8minor_lg from '../../assets/images/gr8-minor-lg.png';

export const Projects = ({list}) => {
 const {manor, finova, greenbug, vertex, gr8} = list;

    return(
        <>
        <section className={styles['projects']}>
            <h2 className={styles['projects_title']}>Projects</h2>
            <section className={styles['projects_container']}>
                {manor && (<ProjectCard cardProps={{icon: manor_lg, icon_sm: manor_sm, title: 'The Manor', link: './works', text: 'Web Design'}}/>)}
                {finova && (<ProjectCard cardProps={{icon: finova_lg, icon_sm: finova_sm, title: 'FinovaForge', link: './works',text: 'Web Design'}}/>)}
                {greenbug && (<ProjectCard cardProps={{icon: green_lg, icon_sm: green_sm, title: 'GreenBug', link: './works',text: 'Web Design'}}/>)}
                {vertex && (<ProjectCard cardProps={{icon: vertex_lg, icon_sm: vertex_sm, title: 'Vertex', link: './works',text: 'Web Design'}}/>)}
                {/* {gr8 && (<ProjectCard cardProps={{icon: '', title:'GR8 Photography', link: './works', text: 'Web Design', gr8: true}} />)} */}
            </section>
        </section>
        </>
    )
}

const Gr8 = () => {
    return (
        <>
        <div className={styles['gr8container']}>
            <figure className={styles['mobile']}>
                <img src={gr8minor_lg} alt="gr8" />
            </figure>
            <figure className={styles['desktop']}>
                <img src={gr8main_lg} alt="gr8" />
            </figure>
        </div>
        </>
    )
}


export const ProjectCard = ({cardProps}) => {
    const {title, icon, icon_sm, text, gr8} = cardProps;

    return(
        <>
        <div className={styles['project-card']}>
            {gr8 ? (<Gr8 />) : (
                <picture className={styles["main-content"]}>
                    <source media="(max-width: 600px)" srcSet={icon_sm}/>
                    <img src={icon} alt={title} />
                </picture>
            )}
            <div className={styles['details']}>
                <div className={styles['details-wrapper']}>
                    <div className={styles['details-container']}>
                        <h3>{title}</h3>
                        <div className={styles['details-others']}>
                            <p>{text}</p>
                            <figure>
                                <img src={chevronRight} alt="" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}