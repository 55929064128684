import styles from './Results.module.scss';
import React from 'react';
import { resultFactors } from './data';

const Results = () => {

    const ResultLogic = () => {
        return (
          <>
              {
                  resultFactors.map(({title, details, icon}) => {
                      return (
                          <article className={styles["factor"]} key={title}>
                              <figure className={styles["icon"]}>
                                  {icon}
                              </figure>
                              <div className={styles["text"]}>
                                  <h3 className={styles["title"]}>{title}</h3>
                                  <p className={styles["details"]}>{details}</p>
                              </div>
                          </article>
                      )
                  })
              }
          </>
        )
      }

  return (
    <div className={styles["result-factors"]}>
        <h2 className={styles["section-title"]}>How we deliver <br /> results?</h2>
        <div className={styles["factors"]}>
            <ResultLogic />
            {/* <figure className={styles["webxiel-logo"]}>
                <img src={webxielLogo} alt="webxiel Logo" />
            </figure>
            <ResultLogic />    */}
        </div>
    </div>
  )
}

export default Results