import React, { useRef }  from 'react'
import AboutSection from './about-us/About'
import styles from './Home.module.scss'
import ServiceSummary from './services/ServiceSummary'
import Testimonial from '../../components/testimonial/Testimonial'
import HeroComponent from '../../components/hero/Hero'
import { Projects } from '../../components/projects/Projects';
import easy_life from '../../assets/images/home/easy-life.png';

function Home() {
  const ref = useRef(null)
  function handleClick(){
    ref.current?.scrollIntoView({behaviour: 'smooth'})
  }
  
  return (
    <main className={styles["home"]}>
      <HeroComponent 
        scrollDown={handleClick} 
        isScroll={true} 
        title={{line1:'We shape', line2:'brands that are', line3:'bold.'}} 
      />
      <section ref={ref}>
        <ServiceSummary />
      </section>
      <Projects list={{vertex:true,manor:true,greenbug:true,finova:true}} />
      <section className={styles["aboutwebxiel"]}>
        <AboutSection />
      </section>
      <section className={styles["easy_life"]}>
        <div className={styles["easy-wrapper"]}>
          <img src={easy_life} alt="easy life iphone" />
          <p>
            Products that make life easy
          </p>
        </div>
      </section>
      <Testimonial />
    </main>
  )
}

export default Home