export const footerLinks = {
    homeLinks: [
        {
            title: "home",
            location: "/home",
        },
        {
            title: "works",
            location: "/works",
        },
        {
            title: "services",
            location: "/services",
        },
        {
            title: "about",
            location: "/about-us",
        }
    ],
    aboutLinks: [
        {
            title: "clients",
            location: "/about-us"
        },
        {
            title: "our team",
            location: "/about-us"
        },
    ],
    socialLinks: [
        {
            title: "instagram",
            location: "https://instagram.com/webxiel?igshid=YmMyMTA2M2Y=",
        },
        {
            title: "twitter",
            location: "https://twitter.com/webxiel?t=9TaDHEMrStSPmNzUYvD9bA&s=09",
        },
        // {
        //     title: "tiktok",
        //     location: "/#",
        // },
        {
            title: "pinterest",
            location: "http://www.pinterest.com/webxiel",
        },
        // {
        //     title: "behance",
        //     location: "http://www.behance.com/webxiel",
        // },
    ]
}


