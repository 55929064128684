import './Button.scss'

import React from 'react'

const Button = ({text, btn = "", lg=false}) => {
  // lg is used for larger buttons
  return (
    <button className={`btn ${btn} ${lg? "btn-lg" : ""}`}>{text}</button>
  )
}

export default Button