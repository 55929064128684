import WebDev from '../../../assets/images/services/web-dev.svg';
import WebDesign from '../../../assets/images/services/web-design.svg';
import BrandDesign from '../../../assets/images/services/brand-design.svg';


export const serviceSummary = [
    {
        icon: WebDev,
        title: "Website Development and Management",
        text: "Stop losing sales to your competitors! Stay one step ahead of your competitors with the professional website we would develop for your business.",
        details: "/services"
    },
    {
        icon: WebDesign,
        title: "Design Implementation",
        text: "Bring on your project designs. You don't have to be stuck. Let our professionals do the work for you.",
        details: "/services"
    },
    {
        icon: BrandDesign,
        title: "Branding",
        text: "A quality brand identity makes you stand out among competitors and attracts bigger clients for your business. Let's give your business the unique brand it needs to be exceptional",
        details: "/services",
    }
]