import React from 'react'
import { navLinksArray } from './NavLinksArray'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../../logo2.svg';
import { ReactComponent as Hamburger } from '../../assets/images/hamburger2.svg';
import Button from '../button/Button';
import './topbar.scss';

const Topbar = ({showNav, closeNav}) => {
  return (
    <header className='topbar'>
        <NavLink to='/home' className='logo'>
            <Logo />
        </NavLink>
        <nav className={showNav ? 'navbar' : 'navbar hide-nav'}>
            {
                navLinksArray.map(({title, key, path}) => 
                    <NavLink to={path} id={key} name={key} key={key} className={({ isActive }) => isActive ? `nav__link--active ${key}-nav` : `nav__link ${key}-nav`}>
                        <p className='nav__link-title'>{title}</p>
                        <figure className='nav__link--dash'>
                            <hr/>
                        </figure>
                    </NavLink> 
                )
            }
        </nav>
        <div className='buttons'>
            <NavLink to='/contact' className={({ isActive }) => isActive ? "contact--active" : "contact"}>
                <Button text="Get in Touch"/>
            </NavLink>
            <figure className='hamburger' onClick={() => closeNav(!showNav)}>
                <Hamburger />
            </figure>
        </div>
    </header>
  )
}

export default Topbar