import styles from "./GroupMission.module.scss"
export const GroupMission = () => {
  return (
    <section className={styles["picture"]}>
      <div className={styles["group"]}>
        <iframe className={styles["youtube_video"]} 
        src="https://www.youtube.com/embed/16EkiCdoIQw?si=3EtmBxnNOWBG54cH" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen>
        </iframe>
      </div>
    </section>
  );
};


