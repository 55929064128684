import clientOne from "../../assets/images/client-one.png";
import clientTwo from "../../assets/images/client-two.png";
import clientThree from "../../assets/images/client_three.jpg";
import clientFour from "../../assets/images/client_four.png";

export const testimonies = [
  {
    image: clientFour,
    id: "1",
    name: "Chidinma Mogul",
    title: "",
    text: "Webxiel team are exceptional. While on engagement, they exhibited advanced IT skills, very tenacious and dogged on all tasks. Their service delivery was excellent and top notch. Highly recommended.",
  },
  {
    image: clientTwo,
    id: "2",
    name: "ben mad",
    title: "",
    text: "For weeks, I've been having difficulties trying to develop my business website, until I got in contact with the MD of Webxiel Limited. And under 72hours, I got my Business Website(@www.mharkiesempire.com) setup. And I must say that This is One of the best Web developing company ever, so good at what they do, Very reliable and perfect with timing.",
  },
  {
    image: clientOne,
    id: "3",
    name: "ben mad",
    title: "",
    text: "Finally I can hiss a sigh  of relief. For almost 6months now to get someone to help me develop my business website was like searching for shark in a lake. Thanks goodness I came in contact with the MD Webxiel and under 2 days, my business website was ready, not just ready but the quality of the work marvels me. He is simply the best. Thank you for been reliable and for the perfect job.",
  },
  {
    image: clientThree,
    id: "4",
    name: "Tolulope Quadri",
    title: "",
    text: "Working with Webxiel has been a great experience for me. I have met people who are passionate about creativity and making customers happy. I have learned more on the job, and I'm glad I also get to contribute to the company's growth in my way.",
  },
];
