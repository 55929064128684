import styles from './About.module.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import team_guys from '../../../assets/images/home/team-guys-lg.png';
import team_guys_sm from '../../../assets/images/team-guys-sm.png';

const AboutSection = () => {
  return (
    <div className={styles["about"]}>
        <p className={styles["details"]}>Together we make a difference
        </p>
        <Link to="/about-us" >
            <button className={styles["about-btn"]}>
                About us  
            </button>
        </Link>
        {/* <figure className={styles["team_guys"]}>
            <img src={team_guys} alt="the team"/>
        </figure> */}
        <picture className={styles["team_guys"]}>
          <source media="(max-width: 420px)" srcset={team_guys_sm} />
          <img src={team_guys} alt="the team"/>
        </picture>
    </div>
  )
}

export default AboutSection