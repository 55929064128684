import krent from '../../../assets/images/services/krent-full.png';
import transhit from '../../../assets/images/services/transhit-full.png';
import story from '../../../assets/images/services/memo.png';
import seo from '../../../assets/images/services/seo-icon.svg';
import api from '../../../assets/images/services/api-icon.svg';
import social from '../../../assets/images/services/social-icon.svg';
import business from '../../../assets/images/services/business-icon.svg';
import content from '../../../assets/images/services/content-icon.svg';
import copy from '../../../assets/images/services/copy-icon.svg';


export const allServices = [
    {
        title: "Branding",
        icon: transhit,
        text: "A quality brand identity makes you stand out among competitors and attracts bigger clients for your business. Let's give your business the unique branding it needs to be exceptional.",
    },
    {
        title: "Website Designing",
        icon: krent,
        text: "Are you thinking of building your online presence for your business? Its time to let us design a professional website for your Business."
    },
    {
        title: "Website Development and Management",
        icon: story,
        text: "Stop losing sales to your competitors! Stay one step ahead of your competitors with the professional website we would develop for your business.",
    },
    {
        title: "Search Engine Optimization (SEO)",
        icon: seo,
        text: "We add value to your site by making it rank higher and reach more audience with our SEO services.",
    },
    {
        title: "API Development",
        icon: api,
        text: "What is better than having happy and satisfied customers? With our API development services, you can add special features that satisy your customers to your sites.",
    },
    {
        title: "Social Media Management",
        icon: social,
        text: "Social media management just got easier! Let's manage your social media handles, engage with your user, and build your audience while you focus your energy on other areas of your business.",
    },
    {
        title: "Business Analysis and Development",
        icon: business,
        text: "We identify your business needs and proffer solutions and tools for productivity and efficiency.",
    },
    {
        title: "Content Development",
        icon: content,
        text: "Self-written content most times is not the best for your business marketing which is why you should let us develop contents that can engage your audience.",
    },
    {
        title: "Copywriting",
        icon: copy,
        text: "Want to reduce your digital advertisement cost and still have a high ROI? Then you need professional copywriting.",
    },
    // {
    //     title: "Design Implementation",
    //     icon: business,
    //     text: "Bring on your project designs. You don’t have to be stuck. Let our professionals do the work for you.",
    // },
    // {
    //     title: "Mobile Application Development",
    //     icon: api,
    //     text: "Mobile apps have become a vital part of the life of your potential customers. That is the paramount reason your business should own a mobile application",
    // },
]