import styles from './ServiceSummary.module.scss';
import React, { Fragment } from 'react'
import { serviceSummary } from './serviceData';
import { ServiceCard } from '../../../components/cards/Cards';
import { ReactComponent as ChevronRight } from '../../../assets/images/services/chevron-right2.svg';
import { Link } from 'react-router-dom';


const ServiceSummary = () => {

    const serviceCards = serviceSummary.map((service) => {
        return (
            <Fragment key={service.title}>
                <ServiceCard size='large' cardProps={service}/>
            </Fragment>
        )
    })


  return (
    <section className={styles['services']}>

        <h2 className={styles['section__intro']}>
            We create products, brands and websites for companies around the world.
        </h2>
        <section className={styles['summary']}>
            <div className={styles['summary_wrapper']}>
                <article className={styles['services__container']}>
                    {serviceCards}
                </article>
            </div>
        </section>
        <Link to='/services' className={styles['more__btn']}>
            <h2>See more services</h2>
            <span className={styles['chevron-right']}><ChevronRight /></span>
        </Link>
    </section>
  )
}

export default ServiceSummary