import styles from "./Testimonial.module.scss";
import React from "react";
import { testimonies } from "./data";
import quoteMark from "../../assets/images/quotation.svg";
import { useEffect, useRef, useState } from "react";
import nextArrow from "../../assets/images/next_arrow.svg";

const Testimonial = ({ showTitle = false }) => {
  const [slide, changeSlide] = useState(0);
  const slideInerval = useRef(null);
  let totalSlides = testimonies.length - 1;

  function nextSlide() {
    changeSlide((prev) => (prev === 0 ? totalSlides : prev - 1));
    startSlideInterval();
  }
  function prevSlide() {
    changeSlide((prev) => (prev === totalSlides ? 0 : prev + 1));
    startSlideInterval();
  }
  const stopSlideInterval = () => {
    if (slideInerval.current) {
      clearInterval(slideInerval.current);
    }
  };
  const startSlideInterval = () => {
    stopSlideInterval();
    slideInerval.current = setInterval(
      () => changeSlide((prev) => (prev === 0 ? totalSlides : prev - 1)),
      3000
    );
  };

  useEffect(() => {
    startSlideInterval();
    return () => stopSlideInterval();
  }, [slide]);

  const testimoniesArray = testimonies.map((testimony) => {
    return (
      <div key={testimony.id}>
        <TestimonyCard slide={slide} details={testimony} />
      </div>
    );
  });

  let nextActive = slide > 0 ? "_active" : "";
  let prevActive = slide < totalSlides ? "_active" : "";

  return (
    <section className={styles["carousel_container"]}>
      <h2 className={styles["title"]}>What our clients say</h2>
      <div className={styles["carousel"]}>{testimoniesArray}</div>
      <div className={styles["carousel_btn"]}>
        <figure
          onClick={() => prevSlide()}
          className={styles[`prev_btn${prevActive}`]}
        >
          <img src={nextArrow} alt="previous testimony" />
        </figure>
        <figure
          onClick={() => nextSlide()}
          className={styles[`next_btn${nextActive}`]}
        >
          <img src={nextArrow} alt="next testimony" />
        </figure>
      </div>
    </section>
  );
};

const TestimonyCard = ({ slide, details }) => {
  let { id, name, title, text, image, quotation = quoteMark } = details;
  let position = slide - (parseInt(id) - 1);
  if (position < 1) position = position + testimonies.length;
  return (
    <>
      <div className={styles[`testimony_card_${position}`]}>
        <figure className={styles["quotation_mark"]}>
          <img src={quotation} alt="quotation" />
        </figure>
        <p className={styles["testimony"]}>{text}</p>
        <div className={styles["testifier"]}>
          <figure className={styles["icon"]}>
            <img src={image} alt={title} />
          </figure>
          <div className={styles["person"]}>
            <p className={styles["name"]}>{name}</p>
            <p className={styles["role"]}>{title}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
