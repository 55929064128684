import React from "react";
import { GroupMission } from "../../components/shares/GroupMission";
import styles from "./About.module.scss";
import HeroComponent from "../../components/hero/Hero";
import mission_icon from "../../assets/images/about/mission_icon.svg";
import vission_icon from "../../assets/images/about/triangles.svg";
import {Projects} from "../../components/projects/Projects";
import Testimonial from "../../components/testimonial/Testimonial";
// import { ScrollBar } from "./scrolComponent";

const About = () => {
  // const title = "We love our clients";
  return (
    <main className={styles["about"]}>
      <HeroComponent title={{
        line1: "Webxiel is",
        line2: "growth, fun,",
        line3: "love"
      }} />
      <GroupMission />
      <section className={styles['vision_mission']}>
        <div className={styles['vision_mission_wrapper']}>
          <article className={styles['intro']}>
            <h3>
              We want to bring business and digital world together
            </h3>
            <p>
              Webxiel is a software development company whose principal goal
              is to enable businesses leverage and thrive with the emerging 
              technologies. We are experts in bringing proven expertise in tech
              environment and we provide bespoken, trendy digital solutions 
              that reflects perfectly to your corporate image.
            </p>
          </article>
          <article className={styles['vision']}>
            <figure className={styles['icon']}>
              <img src={vission_icon} alt="Our vision" />
            </figure>
            <div className={styles['text']}>
              <h3>Our Vision</h3>
              <p>
                We aspire to be the best user-centric company, connecting 
                businesses with their right audience, offering high quality,
                convenient and affordable product and services.
              </p>
            </div>
          </article>
          <article className={styles['mission']}>
            <div className={styles['text']}>
              <h3>Our Mission</h3>
              <p>
                To connect businesses and their customers using the best tools around
                platforms, driving the most secured, user-friendly, cost-effective solution, 
                thereby contributing to a better technological advancement.
              </p>
            </div>
            <figure className={styles['icon']}>
              <img src={mission_icon} alt="Our mission" />
            </figure>
          </article>
        </div>
      </section>
      <Projects list={{manor:true,finova:true}}/>
      <Testimonial />
    </main>
  );
};

export default About;
