import { Fragment } from "react";
import css from "./Footer.module.scss";
import { ReactComponent as Logo } from "../../logo2.svg";
import { ReactComponent as Message } from "../../assets/images/Message2.svg"
import { ReactComponent as Calling } from "../../assets/images/Calling2.svg"
import { Link } from "react-router-dom";
import { footerLinks } from "./data";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

const Footer = () => {
  let homeLinksArray = footerLinks.homeLinks.map(item => {
    const { title, location } = item
    return(
      <li key={title}>
        <Link to={`${location}`} >{title}</Link>
      </li>
    )
  });

  let aboutLinksArray = footerLinks.aboutLinks.map(item => {
    const { title, location } = item
    return(
      <li key={title}>
        <Link to={`${location}`} >{title}</Link>
      </li>
    )
  })
  let socialLinksArray = footerLinks.socialLinks.map(item => {
    const { title, location } = item
    return(
      <li key={title}>
        <a href={`${location}`} target="_blank" rel="noopener noreferrer" >
          {title}
        </a>
      </li>
    )
  })

  let today = new Date();
  let copyText = `Copyright © ${today.getFullYear()} Webxiel Inc. All rights reserved.`;

  return (
    <Fragment>
      <div className={css["general-container"]}>
        <div className={css["btc-shadow"]}>
          <div className={css["btx-container"]}>
            <div className={css["container-build"]}>
              <h2>
                Let’s <span className={css.build}>build </span>something great.
              </h2>
            </div>
            <div className={css["btn-first"]}>
              <Link to='/contact'>Get in touch</Link>
            </div>
          </div>
          <div className={css["webxiel-foot"]}>
            <div className={css.logo}>
              <Logo />
              <p className={css.logo_intro}>
              We're your #1 platform for innovative business and software solutions. Leverage our expertise and experience a geometric digital transformation in your business. Get in touch today and unlock that potential!
              </p>
              <div className={css.logo_contact}>
                <Message />
                <div className={css.logo_contact_content}>
                  <p><a href="mailto:support@webxiel.com ">support@webxiel.com</a></p>
                  <p><a href="mailto:hello@webxiel.io">hello@webxiel.io</a></p>
                </div>
              </div>
              <div className={css.logo_contact}>
                <Calling />
                <div className={css.logo_contact_content}>
                  <p><a href="tel:+2347014346470">+234 701 434 6470</a></p>
                  <p><a href="tel:+2347047948179">+234 704 794 8179</a></p>
                </div>
              </div>
            </div>
            <div className={css["table-list"]}>
              <div className={css["table-child"]}>
                <p>Menu</p>
                <ul>
                  {homeLinksArray}
                </ul>
              </div>
              <div className={css["table-child"]}>
                <p>About</p>
                <ul>
                  {aboutLinksArray}
                </ul>
              </div>
              <div className={css["table-child"]}>
                <p>Social networks</p>
                <ul>
                  {socialLinksArray}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={css["copy-container"]}>
          <p className={css["copy-right"]}>
            {copyText}
          </p>
        </div>
      </div>
      <TawkMessengerReact propertyId="63bc643e47425128790c866c" widgetId="1gmbuf5fa"/>
    </Fragment>
  );
};
export default Footer;
