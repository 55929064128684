export const navLinksArray = [
    {
      key: "Home",
      title: "Home",
      path: "/home",
    },

    {
      title: "Works",
      key: "works",
      path: "/works",
    },
    {
        title: "Services",
        key: "Services",
        path: "/services",
    },
    {
      title: "About",
      key: "About",
      path: "/about-us",
    },
    // {
    //   title: "Join Us",
    //   key: "join us",
    //   path: "/join-us",
    // },
    {
      title: "Get in Touch",
      key: "contact",
      path: "/contact",
    },
  ]